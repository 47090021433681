 /* Import this file using the following HTML or equivalent:
 * <!--[if IE]>
 *   <link href="/stylesheets/ie.css" media="screen, projection" rel="stylesheet" type="text/css" />
 * <![endif]--> */

@import 'variables';
@import 'partials/font-finlek';
// @import 'partials/_font-fontdeckbaskerville';

a[href^='http'],
a[href^='//'] {
  border-bottom: 1px dotted #cccccc;
  color: #777777;
  &:hover {
    border-bottom-color: #777777;
    color: #333333;
  }
}

a[href^='#'] {
  background-color: $c-telescope-bg;
  color: #333333;
  &:hover {
    background-color: $c-telescope-bg-active;
  }
}
